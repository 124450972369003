import React, { Component } from "react"
import { Link, graphql } from "gatsby";
import axios from "axios";
import moment from "moment";
import 'moment/locale/bn';
import { sampleSize } from 'lodash'

import Layout from "../components/layout"
import SEO from "../components/seo"
import Image from "../components/image"
import FeedItem from "../components/feed-item";
import SiteItem from "../components/site-item"

class Newspaper extends Component {
  state = {
    entries: [],
  }
  timer = null;
  componentDidMount() {
    const { data } = this.props

    if (true !== data.markdownRemark.frontmatter.isDead){
      this.updateRss();
  
      this.timer = setInterval( () => {
        this.updateRss();
      }, 600000 );
    }
  }
  componentWillUnmount() {
    if ( this.timer !== null ) {
      clearInterval( this.timer );
    }
  }

  updateRss() {
    const { data } = this.props

    // https://news.google.com/rss/search?hl=bn&gl=BD&q=KEYWORD&ceid=BD:bn
    const rssURL = data.markdownRemark.frontmatter.rss ? encodeURIComponent(data.markdownRemark.frontmatter.rss) : encodeURIComponent(`https://news.google.com/rss/search?hl=bn&gl=BD&q=site:${data.markdownRemark.frontmatter.url}&ceid=BD:bn`);

    // const url = `http://an.local:10063/an-proxy/feed/?url=${rssURL}`;
    const url = `https://an-proxy.herokuapp.com/feed/?url=${rssURL}`;
  
    axios.get( url ).then( ( res ) => {
        if ( res.status === 200 && res.data && res.data.length ) {
          this.setState( {
            entries: res.data,
          } );
        }
      } );
  }

  getRelatedItems() {
    const { data: { related: { nodes } } } = this.props;
    
    const count = this.props.data.markdownRemark.frontmatter.isDead ? 50 : 15;

    return sampleSize(nodes, count);
  }

  render() {
    const { data } = this.props
    const { entries } = this.state;
    moment.locale(data.markdownRemark.frontmatter.lang);

    const title = data.markdownRemark.frontmatter.titleBn ? `${data.markdownRemark.frontmatter.title} (${data.markdownRemark.frontmatter.titleBn})` : data.markdownRemark.frontmatter.title;

    const relatedItems = this.getRelatedItems();

    return (
      <Layout>
        <SEO title={title} description="" />
        <div className="single-newspaper-wrap">
          <div className="single-newspaper">
            <div className="single-newspaper-logo">
              <a
                href={data.markdownRemark.frontmatter.url}
                rel="nofollow noopener noreferrer"
                target="_blank"
              >
                <Image src={data.markdownRemark.frontmatter.id} />
              </a>
            </div>
            <h1>{data.markdownRemark.frontmatter.title}</h1>
            {
              data.markdownRemark.frontmatter.isDead && (
                <p>{data.markdownRemark.frontmatter.title} newspaper is dead!</p>
              )
            }
            {
              true !== data.markdownRemark.frontmatter.isDead && (data.markdownRemark.frontmatter.embed || data.markdownRemark.frontmatter.proxy) && (
                <Link to={data.markdownRemark.fields.slug + "view/"} className="btn" rel="nofollow">
                  Read News
                </Link>
              )
            }
            {
              true !== data.markdownRemark.frontmatter.isDead && (
                <a
                  href={data.markdownRemark.frontmatter.url}
                  className="single-newspaper-link btn-alt"
                  rel="nofollow noopener noreferrer"
                  target="_blank"
                >
                  Visit site{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-external-link"
                  >
                    <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
                    <polyline points="15 3 21 3 21 9"></polyline>
                    <line x1="10" y1="14" x2="21" y2="3"></line>
                  </svg>
                </a>
              )
            }
            <div
              className="single-newspaper-content"
              dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
            />
          </div>

          {
            true !== data.markdownRemark.frontmatter.isDead && entries && entries.length > 0 &&
              <div className="latest-news-wrap mt50">
                <h2 className="mb30">Latest News from {data.markdownRemark.frontmatter.title}</h2>
                <div className="latest-news">
                  {
                    entries.map( entry => (
                      <FeedItem key={ entry.date } { ...entry } lang={ data.markdownRemark.frontmatter.lang } />
                    ) )
                  }
                </div>
                <div className="text-center mt50">
                  {
                    ! data.markdownRemark.frontmatter.embed && ! data.markdownRemark.frontmatter.proxy &&
                    <a href={data.markdownRemark.frontmatter.url} rel="nofollow noopener noreferrer" target="_blank" className="btn">All News from {data.markdownRemark.frontmatter.title}</a>
                  }
                  {
                    (data.markdownRemark.frontmatter.embed || data.markdownRemark.frontmatter.proxy) &&
                    <Link to={data.markdownRemark.fields.slug + "view/"} className="btn" rel="nofollow">All News from {data.markdownRemark.frontmatter.title}</Link>
                  }
                </div>
              </div>
          }
          <div className="related-newspapers">
            {
              data.markdownRemark.frontmatter.isDead && (
                <h2 className="text-center mb30">Read other newspapers</h2>
                )
              }
            {
              true !== data.markdownRemark.frontmatter.isDead && (
                <h2 className="text-center mb30">Related newspapers</h2>
              )
            }
            <div className="newspapers-grid">
              {relatedItems.map((newspaper, i) => (
                <SiteItem
                  key={`${newspaper.frontmatter.id}_${i}`}
                  id={newspaper.frontmatter.id}
                  url={newspaper.frontmatter.url}
                  title={newspaper.frontmatter.title}
                  slug={newspaper.fields.slug}
                  embed={newspaper.frontmatter.embed}
                />
              ))}
            </div>
          </div>
        </div>
        {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
      </Layout>
    )
  }
}

export default Newspaper

export const query = graphql`
  query($slug: String!, $types: [String]) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      fields {
        slug
      }
      frontmatter {
        title
        titleBn
        id
        url
        embed
        rss
        isDead
      }
    }
    related: allMarkdownRemark(sort: { fields: frontmatter___rank }, filter: {frontmatter: {types: {in: $types}, isDead: {ne: true}}}) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          id
          title
          url
          embed
          types
        }
      }
    }
  }
`
