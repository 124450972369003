import React  from 'react';
import moment from "moment";
import striptags from 'striptags';


const FeedItem = ( { thumbnail, link, title, date, content, lang } ) => (
	<div className="latest-news-item">
		{
		thumbnail &&
		<a href={ link } rel="nofollow noopener noreferrer" target="_blank">
			<img src={ thumbnail } alt={title} />
		</a>
		}
		<div className="latest-news-item-info">
			<h3>
				<a href={ link } rel="nofollow noopener noreferrer" target="_blank">{title}</a>
			</h3>
			<div className="news-time">{ moment(date).format( 'D MMMM, YYYY - H:m' ) }</div>
			<p>
				{striptags(content)}
				{' '}
				{
					lang === 'bn' &&
					<a href={ link } rel="nofollow noopener noreferrer" target="_blank">বিস্তারিত</a>
				}
				{
					lang === 'en' &&
					<a href={ link } rel="nofollow noopener noreferrer" target="_blank">Read more</a>
				}
			</p>
		</div>
	</div>
);

export default FeedItem;
